import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  userLoginReducer,
  userRegisterReducer,
} from "./redux/reducers/userReducers";
import { reelListReducer } from "./redux/reducers/reelReducers";
import {
  allRestaurantListReducer,
  recommendedRestaurantListReducer,
  trendingRestaurantListReducer,
} from "./redux/reducers/restaurantReducers";
import { reviewListReducer } from "./redux/reducers/reviewReducers";
import { slotListReducer } from "./redux/reducers/slotReducers";
// import Cookies from "js-cookie";

const reducer = combineReducers({
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  reelList: reelListReducer,
  trendingRestaurantList: trendingRestaurantListReducer,
  recommendedRestaurantList: recommendedRestaurantListReducer,
  reviewList: reviewListReducer,
  allRestaurants: allRestaurantListReducer,
  slotList: slotListReducer,
});

const userInfoFromStorage = sessionStorage.getItem("userInfo")
  ? JSON.parse(sessionStorage.getItem("userInfo"))
  : null;

// const userInfoFromStorage = decodeURIComponent(Cookies.get("userInfo"));

const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
